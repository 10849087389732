@import '../scss';

@mixin createStyle($mapStyle) {
  @each $attribute, $value in $mapStyle {
    @if (type-of($value) != 'map') {
      #{$attribute}: $value;
    } @else if ($attribute == 'disabled') {
      &:disabled,
      &.disabled {
        @each $pseudoAttribute, $pseudoValue in $value {
          #{$pseudoAttribute}: $pseudoValue;
        }
      }
    } @else {
      &:#{$attribute} {
        @each $pseudoAttribute, $pseudoValue in $value {
          #{$pseudoAttribute}: $pseudoValue;
        }
      }
    }
  }
}
