@use '/src/domains/core/tamagoshiv2/tokens/colors';
@use '/src/domains/core/tamagoshiv2/tokens/spacings';
@use '/src/domains/core/tamagoshiv2/tokens/typography';

.root {
  align-items: center;
  background: rgba(0, 0, 0, 0.24);
  border: none;
  border-radius: 0 spacings.$s;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  height: spacings.$m;
  padding: 2px spacings.$xs;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;

  .info {
    color: colors.$foundation-textIconInverted;
    font-size: typography.$fontSizeTiny;
    height: spacings.$m;
    width: spacings.$m;
    margin-right: 2px;
  }

  .label {
    color: colors.$foundation-textIconInverted;
    white-space: nowrap;
  }
}
