@use 'sass:math';
@import '@/core/tamagoshi/scss';

$campaignBorderRadius: 8px;

.container {
  @include size-above('md') {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: stretch;
  }

  @include size-below('md') {
    > div:first-child {
      margin-bottom: $spacingMd;
    }
  }
}

.campaignContainer {
  $h: calc(#{math.div(1, 2) * 100%} - #{$spacingXs});
  flex: 0 0 $h;
  height: $h;
  position: relative;

  @include size-below('md') {
    display: flex;

    &:before {
      content: '';
      display: block;
      padding-bottom: math.div(100, 328) * 100%;
    }
  }
}

.tertiaryCampaign {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;

  border-radius: $campaignBorderRadius;
  outline: 1px solid $n300;
  outline-offset: -1px;
}
