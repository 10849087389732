@import '../scss';

.root {
  display: flex;
  align-items: center;
  line-height: 16px;

  .icon {
    font-size: $textBody2FontSize;
    color: $g700;
  }

  .label {
    @include text('body2');
    margin-left: $spacingXXs;
    color: $primaryColorText;
    font-weight: $fontWeightBold;
  }
}
