$card: var(--mm-radius-card, 4px);
$container: var(--mm-radius-container, 8px);
$corner: var(--mm-radius-corner, 2px);
$full: var(--mm-radius-full, 100vmax);
$modal: var(--mm-radius-modal, 16px);

:export {
  card: $card;
  container: $container;
  corner: $corner;
  full: $full;
  modal: $modal;
}
