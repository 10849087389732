@use '@/core/tamagoshiv2/tokens/spacings';

%section {
  margin-left: auto;
  margin-right: auto;
  max-width: 1424px; // 1376px + 24px + 24px

  @include size-below('md') {
    [class*='_sliderItem_']:first-child {
      // stylelint-disable-next-line declaration-no-important
      margin-left: 0 !important;
    }

    [class*='_slides_'] {
      // stylelint-disable-next-line declaration-no-important
      scroll-padding: spacings.$m !important;

      &:after,
      &:before {
        content: '';
        display: block;
        flex: 0 0 spacings.$m;
      }
    }
  }

  @include size-above('md') {
    padding-left: spacings.$l;
    padding-right: spacings.$l;
  }
}
