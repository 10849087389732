@use '/src/domains/core/tamagoshiv2/tokens/colors';
@use '/src/domains/core/tamagoshiv2/tokens/radius';
@use '/src/domains/core/tamagoshiv2/tokens/spacings';
@use 'sass:math';
@import '@/core/tamagoshi/scss';

$screenSizeNeedingScroll: 608px;

.homepageSecondaryCampaigns {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;

  @include hideScrollbar();

  margin-top: spacings.$m;
  padding-left: 0;

  @media screen and (max-width: $screenSizeNeedingScroll) {
    overflow: auto;
  }

  @include size-below('md') {
    width: 100%;
    padding-left: spacings.$m;

    &:after {
      content: '';
      display: block;
      flex: 0 0 spacings.$m;
    }
  }
}

.campaignContainer {
  position: relative;

  min-width: 280px;
  $w: calc(50% - #{spacings.$m});
  flex: 0 0 $w;
  width: $w;

  &:first-child {
    margin-right: spacings.$m;
  }

  @include size-above('md') {
    $w: calc(50% - #{spacings.$s});
    flex: 0 0 $w;
    width: $w;
  }

  &:before {
    content: '';
    display: block;
    padding-bottom: math.div(100, 280) * 100%;

    @include size-above('md') {
      padding-bottom: math.div(142, 680) * 100%;
    }
  }
}

.homepageSecondaryCampaign {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;

  border-radius: radius.$container;
  outline: 1px solid $n300;
  outline-offset: -1px;
}

.skeleton {
  position: relative;

  margin-top: spacings.$m;

  min-width: 280px;
  $w: calc(50% - #{spacings.$m});
  flex: 0 0 $w;
  width: $w;

  &:first-child {
    margin-right: spacings.$m;
  }

  @include size-above('md') {
    $w: calc(50% - #{spacings.$s});
    flex: 0 0 $w;
    width: $w;
  }

  &:before {
    content: '';
    display: block;
    padding-bottom: math.div(100, 280) * 100%;

    @include size-above('md') {
      padding-bottom: math.div(142, 680) * 100%;
    }
  }
}
