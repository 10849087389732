@use '/src/domains/core/tamagoshiv2/tokens/colors';
@use '/src/domains/core/tamagoshiv2/tokens/radius';
@use '/src/domains/core/tamagoshiv2/tokens/spacings';
@use '/src/domains/core/tamagoshiv2/tokens/typography';

.root {
  border-radius: spacings.$s;
  box-shadow: 0 4px 8px rgba(12, 25, 58, 0.24);
  cursor: pointer;
  position: relative;
  width: 280px;
  height: 280px;

  &:not(:first-child) {
    margin-left: spacings.$m;
  }
}

.container {
  width: 280px;
  height: 280px;
  border-radius: spacings.$s;
  background-color: var(--banner-main-color);

  &.isB2B {
    background-blend-mode: multiply;
    background-image: url('../../assets/b2b-background.png');
  }
}

.image {
  border-radius: spacings.$s;
}

.labelContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: spacings.$m;
  height: 152px;
  width: 145px;
  border-bottom-left-radius: radius.$container;
  border-bottom-right-radius: radius.$container;
  padding: spacings.$xs;
  background-color: var(--banner-accent-color);
  color: var(--banner-main-color);
}

.label {
  display: flex;
  flex-direction: column;
  margin: spacings.$s spacings.$s;
  color: var(--banner-main-color);
}

.line {
  font-weight: typography.$fontWeightBold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.bigLine {
    font-size: 36px;
    margin-top: -10px;
  }

  &.middleLine {
    font-size: 18px;
  }

  &.smallLine {
    font-size: typography.$fontSizeSmall;
    margin-top: -5px;
  }
}

.subtitle {
  margin-top: spacings.$xs;
  font-size: 14px;
  line-height: 16px;
}

.brand {
  background-color: colors.$foundation-backgroundInverted;
  height: 35px;
  width: 100%;
  padding: spacings.$xs;
  border-radius: radius.$container;
  margin-top: auto;
  display: flex;
  justify-content: center;
}

.brandImage {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
