/// SASS variables for typography.
/// You should not use those directly but the `Typography` components instead.

$fontFamily: 'Open Sans', mm-main, tahoma, arial, sans-serif !default;

// TODO replace that by proper token once the layout is implemented
$mobileAndTabletBreakpoint: 1023px;

@mixin initialize {
  @media screen and (max-width: $mobileAndTabletBreakpoint) {
    :root {
      --mm-font-height-display: 38px;
      --mm-font-height-h1: 30px;
      --mm-font-height-h2: 26px;
      --mm-font-height-h3: 24px;
      --mm-font-height-h4: 22px;
      --mm-font-size-display: 32px;
      --mm-font-size-h1: 24px;
      --mm-font-size-h2: 20px;
      --mm-font-size-h3: 18px;
      --mm-font-size-h4: 16px;
    }
  }
}

// Text
$fontSizeBody1: var(--mm-font-size-body1, 16px);
$fontSizeBody2: var(--mm-font-size-body2, 14px);
$fontSizeSmall: var(--mm-font-size-small, 12px);
$fontSizeTiny: var(--mm-font-size-tiny, 10px);
$lineHeightBody1: var(--mm-font-height-body1, 22px);
$lineHeightBody2: var(--mm-font-height-body2, 20px);
$lineHeightSmall: var(--mm-font-height-small, 18px);
$lineHeightTiny: var(--mm-font-height-tiny, 16px);

// Titles
$fontSizeDisplay: var(--mm-font-size-display, 40px);
$fontSizeH1: var(--mm-font-size-h1, 32px);
$fontSizeH2: var(--mm-font-size-h2, 24px);
$fontSizeH3: var(--mm-font-size-h3, 20px);
$fontSizeH4: var(--mm-font-size-h4, 16px);
$lineHeightDisplay: var(--mm-font-height-display, 46px);
$lineHeightH1: var(--mm-font-height-h1, 38px);
$lineHeightH2: var(--mm-font-height-h2, 30px);
$lineHeightH3: var(--mm-font-height-h3, 26px);
$lineHeightH4: var(--mm-font-height-h4, 22px);

// Weights
$fontWeightBold: var(--mm-font-weight-bold, 700);
$fontWeightRegular: var(--mm-font-weight-regular, 400);
$fontWeightSemiBold: var(--mm-font-weight-semiBold, 600);
