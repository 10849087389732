/* stylelint-disable declaration-no-important */
@import '@/core/tamagoshi/scss';

.title {
  @include text('body1');
  font-weight: $fontWeightBold;
  margin: 0 0 $spacingXs;
}

.description {
  @include text('body2');
  margin: 0 0 $spacingXs;
}

.link {
  @include text('body2');
  display: inline !important;
  font-weight: $fontWeightSemiBold;
  text-decoration: underline;
}

.name {
  @include text('body2');
  margin: 0;
}
