// You should no longer use those variables and mixins.
// Please use the Typography components instead.

$textDisplayFontSize: 48px !default;
$textDisplayLineHeight: 64px !default;
$textH1FontSize: 40px !default;
$textH1LineHeight: 56px !default;
$textH2FontSize: 32px !default;
$textH2LineHeight: 44px !default;
$textH3FontSize: 24px !default;
$textH3LineHeight: 32px !default;
$textH4FontSize: 20px !default;
$textH4LineHeight: 28px !default;
$textBody1FontSize: 16px !default;
$textBody1LineHeight: 24px !default;
$textBody2FontSize: 14px !default;
$textBody2LineHeight: 20px !default;
$textSmallFontSize: 12px !default;
$textSmallLineHeight: 16px !default;
$textXSmallFontSize: 11px !default;
$textXSmallLineHeight: 16px !default;
$textTinyFontSize: 10px !default;
$textTinyLineHeight: 16px !default;
$fontWeightBolder: 800 !default;
$fontWeightBold: 700 !default;
$fontWeightSemiBold: 600 !default;
$fontWeightRegular: 400 !default;

@mixin text($type, $font: 'regular') {
  @warn 'The mixin text is deprecated. Please use the Typography components instead.';
  @if $type == 'display' {
    font-size: $textDisplayFontSize;
    line-height: $textDisplayLineHeight;
  } @else if $type == 'h1' {
    font-size: $textH1FontSize;
    line-height: $textH1LineHeight;
  } @else if $type == 'h2' {
    font-size: $textH2FontSize;
    line-height: $textH2LineHeight;
  } @else if $type == 'h3' {
    font-size: $textH3FontSize;
    line-height: $textH3LineHeight;
  } @else if $type == 'h4' {
    font-size: $textH4FontSize;
    line-height: $textH4LineHeight;
  } @else if $type == 'body1' {
    font-size: $textBody1FontSize;
    line-height: $textBody1LineHeight;
  } @else if $type == 'body2' {
    font-size: $textBody2FontSize;
    line-height: $textBody2LineHeight;
  } @else if $type == 'small' {
    font-size: $textSmallFontSize;
    line-height: $textSmallLineHeight;
  } @else if $type == 'xsmall' {
    font-size: $textXSmallFontSize;
    line-height: $textXSmallLineHeight;
  } @else {
    font-size: $textTinyFontSize;
    line-height: $textTinyLineHeight;
  }
  @if $font == 'regular' {
    font-weight: $fontWeightRegular;
  } @else if $font == 'semiBold' {
    font-weight: $fontWeightSemiBold;
  } @else {
    font-weight: $fontWeightBold;
  }
}
