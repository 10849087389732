@use '/src/domains/core/tamagoshiv2/tokens/colors';
@use '/src/domains/core/tamagoshiv2/tokens/colors/_palette';
@import '../scss/index';

.root {
  display: inline-flex;
  align-items: center;
  width: min-content;
  height: 16px;
  white-space: nowrap;
  padding: 2px 4px;
  border-radius: 2px;
  font-weight: $fontWeightBold;

  svg {
    height: 11px;
    width: 11px;
    margin-right: $spacingXXs;
  }

  @include text('tiny', 'bold');

  &.default {
    background-color: $n300;
    color: $n800;
  }

  &.yellow {
    background-color: $v400;
    color: $v900;
  }

  &.beige {
    background-color: colors.$warning-backgroundPrimary;
    color: colors.$foundation-textIconPrimary;
  }

  &.red {
    background-color: $r600;
    color: $n0;
  }

  &.darkBlue {
    background-color: $n800;
    color: $ba200;
  }

  &.blue {
    background-color: $b400;
    color: $n0;
  }

  &.green {
    background-color: $g800;
    color: $n0;
  }

  &.lightBlue {
    background-color: $b200;
    color: $b600;

    &.revamp {
      background-color: colors.$brand-backgroundSecondary;
      color: colors.$foundation-textIconPrimary;
    }
  }

  &.lightGrey {
    background-color: colors.$foundation-backgroundPrimary;
    color: colors.$foundation-textIconPrimary;
  }

  &.black {
    background-color: colors.$foundation-backgroundQuinary;
    color: colors.$foundation-textIconInverted;
  }

  &.orange {
    background-color: $v500;
    color: colors.$brand-textIconPrimary;

    &.revamp {
      background-color: colors.$accent-backgroundPrimary;
      color: colors.$foundation-textIconPrimary;
    }
  }

  &.pro {
    background-color: $v500;
    color: colors.$foundation-textIconPrimary;
  }

  &.red &.revamp {
    background-color: colors.$promotion-textIconPrimary;
    color: colors.$foundation-textIconInverted;
  }

  &.lightGreen {
    background-color: colors.$success-backgroundPrimary;
    color: colors.$foundation-textIconPrimary;
  }

  &.borderColor {
    background-color: transparent;
    font-weight: $fontWeightSemiBold;

    &.default {
      border: 1px solid $n300;
      color: $n300;
    }

    &.yellow {
      border: 1px solid $v400;
      color: $v400;
    }

    &.red {
      border: 1px solid $r600;
      color: $r600;
    }

    &.darkBlue {
      border: 1px solid $n800;
      color: $n800;
    }

    &.blue {
      border: 1px solid $b400;
      color: $b400;
    }

    &.green {
      border: 1px solid $g800;
      color: $g800;
    }
  }
}
