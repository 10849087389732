@import '../../../scss';

// To make carousel arrow CSS rule more important than Button CSS rule
.carouselArrow.carouselArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &:disabled,
  &[aria-isdisabled='true'] {
    pointer-events: none;
  }
}

.carouselArrow {
  &.smallArrows {
    min-height: 24px;
    min-width: 24px;
  }
}

.carouselArrowLeft {
  left: $spacingMd;
  &.smallArrowSpacing {
    left: 4px;
  }
}

.carouselArrowRight {
  right: $spacingMd;
  &.smallArrowSpacing {
    right: 4px;
  }
}

.rootIcon {
  &.smallArrow {
    width: $spacingMd;
    height: $spacingMd;
  }
}
