$xs: var(--mm-spacing-xs, 4px);
$s: var(--mm-spacing-s, 8px);
$m: var(--mm-spacing-m, 16px);
$l: var(--mm-spacing-l, 24px);
$xl: var(--mm-spacing-xl, 32px);
$xxl: var(--mm-spacing-xxl, 40px);
$xxxl: var(--mm-spacing-xxxl, 48px);

:export {
  xs: $xs;
  s: $s;
  m: $m;
  l: $l;
  xl: $xl;
  xxl: $xxl;
  xxxl: $xxxl;
}
