@use './palette';

$backgroundPrimary: var(--mm-color-brand-background-primary, palette.$B500);
$backgroundPrimaryActive: var(--mm-color-brand-background-primaryActive, palette.$B700);
$backgroundPrimaryHover: var(--mm-color-brand-background-primaryHover, palette.$B300);
$backgroundSecondary: var(--mm-color-brand-background-secondary, palette.$B100);
$borderPrimary: var(--mm-color-brand-border-primary, palette.$B500);
$textIconPrimary: var(--mm-color-brand-textIcon-primary, palette.$B500);

:export {
  backgroundPrimary: $backgroundPrimary;
  backgroundPrimaryActive: $backgroundPrimaryActive;
  backgroundPrimaryHover: $backgroundPrimaryHover;
  backgroundSecondary: $backgroundSecondary;
  borderPrimary: $borderPrimary;
  textIconPrimary: $textIconPrimary;
}
