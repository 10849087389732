@use '@/core/tamagoshiv2/tokens/spacings';
@import '@/retention/Homepage/styles/carousel';
@import '@/retention/Homepage/styles/section';

.root {
  padding-bottom: spacings.$xl;

  &:first-child {
    padding-top: spacings.$l;
  }

  div,
  :before {
    // stylelint-disable-next-line declaration-no-important
    animation: none !important;
  }
}

.carousel {
  @extend %carousel;
}

.carouselCard {
  @extend %carouselCard;
}

.carouselControls {
  @extend %carouselControls;
}

.carouselSlider {
  @extend %carouselSlider;
  min-height: 336px;
}

.carouselTitle {
  @extend %carouselTitle;
}

.popoverContent.popoverContent {
  width: max-content;
}

.section {
  @extend %section;
}
