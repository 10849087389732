@use './palette';

$backgroundPrimary: var(--mm-color-promotion-background-primary, palette.$R900);
$backgroundSecondary: var(--mm-color-promotion-background-secondary, palette.$R100);
$borderPrimary: var(--mm-color-promotion-border-primary, palette.$R900);
$textIconPrimary: var(--mm-color-promotion-textIcon-primary, palette.$R900);

:export {
  backgroundPrimary: $backgroundPrimary;
  backgroundSecondary: $backgroundSecondary;
  borderPrimary: $borderPrimary;
  textIconPrimary: $textIconPrimary;
}
