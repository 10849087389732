@import '@/core/tamagoshi/scss';

.root {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 16px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.24);
  padding: 2px $spacingXXs;

  &.withRadius {
    border-radius: 0 $spacingXs;
  }

  .infoIcon {
    font-size: $textBody1FontSize;
    color: $n0;
    margin-right: 2px;
  }

  .label {
    white-space: nowrap;
    color: $n0;
    cursor: pointer;
  }
}
