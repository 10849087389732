@import '../../../scss';

.carouselArrow {
  display: none;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}

.alwaysShowButtonsOnMobile.carouselArrow {
  display: flex;
  opacity: 1;
}

.carouselIndicators {
  position: absolute;
  bottom: $spacingMd;
  right: $spacingMd;

  margin: 0;
  padding: $spacingXXs;
  list-style-type: none;

  display: flex;
  background-color: $n500;
  border-radius: $spacingXs;

  &.smallControls {
    bottom: 0;
    padding: 2px;
    border-radius: $spacingMd;
    left: 50%;
    right: auto;
    transform: translate(-50%, 0);
    display: flex;
    opacity: 1;
    transition: opacity 0.25s ease-in;
  }
}

@include size-above('md') {
  .alwaysShowButtonsOnDesktop.carouselArrow {
    opacity: 0.64;
  }

  .carouselArrow {
    display: flex;
  }

  .carouselIndicators.smallControls {
    opacity: 0;
  }

  :hover > .smallControls {
    opacity: 1;
  }

  :hover > .carouselArrow {
    opacity: 1;
  }
}
