@import '../scss';
@import 'mixins';
@import 'variables';

.button {
  box-sizing: border-box;
  font-family: $buttonFontFamily;
  color: $n800;
  width: auto;
  max-width: $maxWidth;
  padding: 0 $buttonPadding;
  margin: $focusEffectWidth; // needed to let room for focus effect
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  background-color: transparent;
  border-radius: $buttonBorderRadius;
  transition: background-color 0.3s ease-in;
  border: 1px solid transparent;

  // Focusing the button with a mouse, touch, or stylus will not show the effect.
  &:not([aria-disabled='true']):focus-visible {
    border: 1px solid $focusBorderColor !important; /* stylelint-disable-line declaration-no-important */
    box-shadow: 0 0 0 $focusEffectWidth $focusEffectColor;
  }

  &[aria-disabled='true'] {
    cursor: default;
  }

  &:first-letter {
    text-transform: uppercase;
  }
}

.iconOnly {
  padding: 0;
}

.fullWidth {
  width: calc(100% - #{$focusEffectWidth * 2});
  max-width: unset;
}

.label {
  width: 100%;
  max-width: unset;
}

.icon {
  color: inherit;
  display: inherit;

  svg {
    height: $iconSize;
    width: $iconSize;
    fill: currentcolor;
  }
}

.iconLeft {
  margin-right: $spacingXXs;
}

.iconRight {
  margin-left: $spacingXXs;
}

.themeDefault {
  @include createStyle($defaultTheme);
}

.themePro {
  @include createStyle($proTheme);
}

.themeProMarketing {
  @include createStyle($proMarkettingTheme);
}

.variantPrimary {
  @include createStyle($primaryVariant);
}

.variantSecondary {
  @include createStyle($secondaryVariant);
}

.variantTertiary {
  @include createStyle($tertiaryVariant);
}

.shapeRound {
  @include createStyle($shapeRound);
}

.variantSecondary.themePro,
.variantSecondary.themeDefault {
  @include createStyle($secondaryVariantColors);
}

.variantTertiary.themePro,
.variantTertiary.themeDefault {
  @include createStyle($tertiaryVariantColors);
}

.noWordWrap {
  white-space: nowrap;
}
