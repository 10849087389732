@import '@/core/tamagoshi/scss';

.brand {
  position: relative;

  $w: 100px;
  flex: 0 0 $w;
  max-width: $w;
  width: $w;
  height: 62px;

  padding: $spacingXs;
  list-style-type: none;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  background-color: $n0;
  border: 1px solid $n300;
  border-radius: $spacingXs;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.img {
  display: block;
  max-width: 100%;
  /* stylelint-disable declaration-no-important */
  width: auto !important;
  height: auto !important;
  /* stylelint-enable declaration-no-important */
}
