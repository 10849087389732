// stylelint-disable color-no-hex
@import '../scss';

$focusEffectWidth: 2px;
$focusEffectColor: #12bbff;
$focusBorderColor: $n0;
$maxWidth: 360px !default;
$iconSize: 24px !default;
$buttonBorderRadius: $defaultBorderRadius !default;
$buttonTextColor: $n0 !default;
$buttonFontFamily: $fontFamily !default;
$buttonFontWeight: $fontWeightSemiBold !default;
$buttonPadding: $spacingMd !default;

$defaultTheme: (
  background-color: $primaryColor,
  hover: (
    background-color: $primaryColorHover,
  ),
  active: (
    background-color: #1b9e9f,
  ),
  disabled: (
    color: $n500,
    background-color: $n300,
  ),
);

$proTheme: (
  color: $n800,
  background-color: $v500,
  hover: (
    background-color: $v400,
  ),
  active: (
    background-color: $v600,
  ),
  disabled: (
    color: $n500,
    background-color: $n300,
  ),
);

$proMarkettingTheme: (
  color: $n0,
  background: linear-gradient(90deg, $r400 0%, $o400 100%),
  hover: (
    background: linear-gradient(90deg, $r500 0%, $o400 100%),
  ),
  active: (
    background: linear-gradient(90deg, $r500 0%, $o600 100%),
  ),
  disabled: (
    color: $n0,
    background: linear-gradient(90deg, $r300 0%, $o200 100%),
  ),
);

$primaryVariant: (
  min-height: 48px,
  min-width: 48px,
  font-size: 16px,
  font-weight: $buttonFontWeight,
);

$secondaryVariant: (
  min-height: 48px,
  min-width: 48px,
  font-size: 16px,
  font-weight: $buttonFontWeight,
);
$secondaryVariantColors: (
  border: 1px solid $n800,
  background-color: $n0,
  hover: (
    color: $n700,
    border-color: $n700,
  ),
  active: (
    color: $n800,
    border-color: $n800,
    background-color: $n200,
  ),
  disabled: (
    color: $n500,
    border-color: $n400,
  ),
);

$tertiaryVariant: (
  min-height: 32px,
  min-width: 32px,
  font-size: 14px,
  font-weight: $buttonFontWeight,
);
$tertiaryVariantColors: (
  color: $n700,
  border: 1px solid transparent,
  background-color: transparent,
  hover: (
    color: $n700,
    background-color: $n100,
  ),
  active: (
    color: $n700,
    background-color: $n200,
  ),
  disabled: (
    color: $n500,
  ),
);

$shapeRound: (
  color: $n800,
  background-color: $n0,
  border: 1px solid $n400,
  border-radius: 50%,
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08),
  hover: (
    background-color: $n300,
    border-color: $n800,
  ),
  active: (
    background-color: $n0,
    border: 1.5px solid $n800,
  ),
);
