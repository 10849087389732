@use '@/core/tamagoshiv2/tokens/colors';
@use '@/core/tamagoshiv2/tokens/typography';

.text {
  font-family: typography.$fontFamily;

  a {
    text-decoration: underline;
    cursor: pointer;
    color: inherit;
    font-weight: typography.$fontWeightSemiBold;

    &:hover {
      color: colors.$info-textIconPrimary;
    }
    &:focus,
    &:active {
      color: colors.$brand-textIconPrimary;
    }
  }
}

.title,
.bold,
.text strong {
  font-weight: typography.$fontWeightBold;
}

.semiBold {
  font-weight: typography.$fontWeightSemiBold;
}

.regular {
  font-weight: typography.$fontWeightRegular;
}

.display {
  font-size: typography.$fontSizeDisplay;
  line-height: typography.$lineHeightDisplay;
}

.h1 {
  font-size: typography.$fontSizeH1;
  line-height: typography.$lineHeightH1;
}

.h2 {
  font-size: typography.$fontSizeH2;
  line-height: typography.$lineHeightH2;
}

.h3 {
  font-size: typography.$fontSizeH3;
  line-height: typography.$lineHeightH3;
}

.h4 {
  font-size: typography.$fontSizeH4;
  line-height: typography.$lineHeightH4;
}

.body1 {
  font-size: typography.$fontSizeBody1;
  line-height: typography.$lineHeightBody1;
}

.body2 {
  font-size: typography.$fontSizeBody2;
  line-height: typography.$lineHeightBody2;
}

.small {
  font-size: typography.$fontSizeSmall;
  line-height: typography.$lineHeightSmall;
}

.tiny {
  font-size: typography.$fontSizeTiny;
  line-height: typography.$lineHeightTiny;
}
