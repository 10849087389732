@use '/src/domains/core/tamagoshiv2/tokens/colors';
@use '/src/domains/core/tamagoshiv2/tokens/radius';
@use '/src/domains/core/tamagoshiv2/tokens/spacings';
@use '/src/domains/core/tamagoshiv2/tokens/typography';
@import '@/core/tamagoshi/scss';

$breakpoint948: 948px;
$breakpoint1424: 1424px;

.container {
  display: flex;
  background-color: var(--banner-main-color);
  position: relative;
  padding: spacings.$m;

  height: 100%;
  width: 100%;

  @include size-below('md') {
    gap: spacings.$s;
  }

  &.isB2B {
    background-blend-mode: multiply;
    background-image: url('../../assets/b2b-background.png');
  }

  a {
    text-decoration: none;
  }
}

.label {
  display: flex;
  flex-direction: column;
  color: var(--banner-accent-color);
  padding: spacings.$s;
  width: 52%;
}

.line {
  font-weight: typography.$fontWeightBold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.middleLine {
    --font-size-ratio: 0.018739;
    --font-size-offset: 0.316026px;
    font-family: sans-serif;
    font-size: calc(var(--font-size-offset) + 100vw * var(--font-size-ratio));
  }

  &.bigLine {
    --font-size-ratio: 0.030537;
    --font-size-offset: 0.515006px;
    font-family: sans-serif;
    font-size: calc(var(--font-size-offset) + 100vw * var(--font-size-ratio));
  }

  &.smallLine {
    --font-size-ratio: 0.011104;
    --font-size-offset: 0.187275px;
    font-family: sans-serif;
    font-size: calc(var(--font-size-offset) + 100vw * var(--font-size-ratio));
  }

  @media only screen and (min-width: $breakpoint1424) {
    &.bigLine {
      font-size: 44px;
    }

    &.middleLine {
      font-size: 27px;
    }

    &.smallLine {
      font-size: typography.$fontSizeH4;
    }
  }

  @media only screen and (max-width: $breakpoint948) {
    &.bigLine {
      font-size: 28px;
    }

    &.middleLine {
      font-size: 18px;
    }

    &.smallLine {
      font-size: typography.$fontSizeSmall;
    }
  }
}

.subtitle {
  margin-top: spacings.$m;
  color: var(--banner-labor_text-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  --font-size-ratio: 0.012492;
  --font-size-offset: 0.210684px;
  font-family: sans-serif;
  font-size: calc(var(--font-size-offset) + 100vw * var(--font-size-ratio));

  @media only screen and (min-width: $breakpoint1424) {
    font-size: 18px;
  }

  @media only screen and (max-width: $breakpoint948) {
    font-size: typography.$fontSizeSmall;
  }
}

.imgContainer {
  width: 48%;
  height: 100%;
  margin: auto;

  .brand {
    background-color: colors.$foundation-backgroundInverted;
    height: 25%;
    width: 100%;
    padding: spacings.$s;
    border-radius: radius.$card;
    margin-top: auto;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: $breakpoint948) {
      padding: spacings.$xs;
    }
  }

  .brandImage {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }

  .image {
    height: auto;
    width: 100%;
    margin-top: spacings.$s;

    object-fit: cover;
    background-color: var(--banner-secondary-color);

    border-radius: radius.$container;
  }
}
