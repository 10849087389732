@import '../../../scss';

.dotIndicator {
  &:not(:first-child) {
    margin-left: $spacingXs;

    &.smallIndicator {
      margin-left: $spacingXXs;
    }
  }
}

.pageIndicator {
  background-color: $n0;
  padding: $spacingXXs;
  font-weight: $fontWeightBold;
  font-size: $defaultFontSize;
  color: $n800;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  align-items: center;
}

.icon {
  display: flex;
  height: 16px;
  margin-right: $spacingXXs;
}
