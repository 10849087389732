@import '@/core/tamagoshi/scss';

.container {
  margin-bottom: $spacingMd;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;
}

.title.title {
  margin: 0;

  @include text('h4', 'bold');
  color: $primaryColorDark;

  @include size-below('xs') {
    font-size: 18px;
  }
}
