@use './palette';

$backgroundPrimary: var(--mm-color-info-background-primary, palette.$I100);
$backgroundSecondary: var(--mm-color-info-background-secondary, palette.$B200);
$backgroundTertiary: var(--mm-color-info-background-tertiary, palette.$I500);
$borderPrimary: var(--mm-color-info-border-primary, palette.$I500);
$textIconPrimary: var(--mm-color-info-textIcon-primary, palette.$I500);

:export {
  backgroundPrimary: $backgroundPrimary;
  backgroundSecondary: $backgroundSecondary;
  backgroundTertiary: $backgroundTertiary;
  borderPrimary: $borderPrimary;
  textIconPrimary: $textIconPrimary;
}
