@use '/src/domains/core/tamagoshiv2/tokens/colors';
@use '/src/domains/core/tamagoshiv2/tokens/radius';
@use '/src/domains/core/tamagoshiv2/tokens/spacings';
@use '/src/domains/core/tamagoshiv2/tokens/typography';

.banner {
  position: relative;
  height: 100%;
  border-radius: radius.$container;
}

.root {
  cursor: pointer;
  padding: 0;
  display: block;
  border: none;
  margin: 0;
  width: 100%;
  height: 100%;
  text-decoration: none;
}
