@import '../scss';

.carousel {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.container {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;

  > * {
    min-width: 100%;
  }
}

@include size-above('md') {
  .carousel {
    border-radius: $spacingXs;
  }
}
