@import '../scss';

.spinner {
  position: relative;
  overflow: hidden;
  margin: auto;

  svg {
    animation: spiinerAnimateSvg 2s linear infinite;
    width: 100%;
    height: 100%;
  }

  circle {
    animation: spinnerAnimateCircle 1.4s ease-in-out infinite both;
    display: block;
    fill: transparent;
    stroke: $n600;
    stroke-linecap: round;
    stroke-dasharray: 283;
    stroke-dashoffset: 280;
    stroke-width: 10px;
    transform-origin: 50% 50%;
  }
}

.spinnerSm {
  width: 16px;
  height: 16px;
}

.spinnerMd {
  width: 24px;
  height: 24px;
}

.spinnerLg {
  width: 40px;
  height: 40px;
}

.spinnerAuto {
  width: 100%;
  height: 100%;
}

@keyframes spiinerAnimateSvg {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes spinnerAnimateCircle {
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
}
