@import '../scss';

@keyframes loading {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

%skeleton,
.skeleton {
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.skeleton:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  will-change: transform;
  transform: translateX(0);

  animation: loading 2s linear infinite;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}

// Themes
%themeDefault,
.themeDefault {
  background-image: linear-gradient(to right, $n300, $n400);
}

.themeDark {
  background-image: linear-gradient(to right, $n400, $n500);
}

// Sizes

.largest {
  height: 44px;
  width: auto;
}

.h1 {
  height: 36px;
  width: auto;
}

.h2 {
  height: 28px;
  width: auto;
}

.h3 {
  height: 20px;
  width: auto;
}

.h4 {
  height: 16px;
  width: auto;
}

.largeParagraph {
  height: 12px;
  width: auto;
}

.paragraph {
  height: 10px;
  width: auto;
}

.smallParagraph {
  height: 8px;
  width: auto;
}

.tinyParagraph {
  height: 6px;
  width: auto;
}

.bigButton {
  height: 48px;
  width: 140px;
}

.button {
  height: 40px;
  width: 100px;
}

.smallButton {
  height: 32px;
  width: 80px;
}
