@use 'sass:math';
@import '@/core/tamagoshi/scss';
@import '@/retention/Homepage/styles/section';

.campaignsContainer {
  @extend %section;

  padding: 0 0 $spacingLg;
  max-width: 1424px; // 1376px + 24px + 24px

  @include size-above('md') {
    padding-top: $spacingLg;
    padding-left: $spacingLg;
    padding-right: $spacingLg;
  }

  @include size-above('lg') {
    padding-bottom: $spacingXl;
  }
  &.withoutPaddingBottom {
    padding-bottom: 0;
  }
}

.campaigns {
  @include size-above('md') {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: stretch;
  }
}

.carousel {
  @include size-above('md') {
    $w: calc(#{math.div(2, 3) * 100%} - #{$spacingXs});
    flex: 0 0 $w;
    width: $w;
  }
}

.tertiaryCampaigns {
  $w: calc(#{math.div(1, 3) * 100%} - #{$spacingXs});
  flex: 0 0 $w;
  width: $w;

  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: stretch;

  @include size-below('md') {
    display: none;
  }
}
