@import '../scss';

.root {
  font-family: $fontFamily;
  letter-spacing: normal;

  &:focus {
    outline: none;
  }
}

.primary {
  color: $n0;
}

.primaryDark {
  color: $primaryColorDark;
}

.display {
  @include text('display');
}

.h1 {
  @include text('h1');
}

.h2 {
  @include text('h2');
}

.h3 {
  @include text('h3');
}

.h4 {
  @include text('h4');
}

.body1 {
  @include text('body1');
}

.body2 {
  @include text('body2');
}

.small {
  @include text('small');
}

.tiny {
  @include text('tiny');
}

.regular {
  font-weight: $fontWeightRegular;
}

.semiBold {
  font-weight: $fontWeightSemiBold;
}

.bold {
  font-weight: $fontWeightBold;
}
