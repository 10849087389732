// If you ever change the breakpoints you need to change them on the MediaQuery and Column components!
$xsWidth: 360px !default;
$smWidth: 600px !default; /* is flagged as secondary. Meaning it is an optional breakpoint */
$mdWidth: 1024px !default;
$lgWidth: 1440px !default;
$defaultFontSize: 12px !default;
$defaultBorderRadius: 4px !default;
$fontFamily: 'Open Sans', mm-main, tahoma, arial, sans-serif !default;
$fontWeightBold: 700 !default;
$fontWeightSemiBold: 600 !default;
$fontWeightRegular: 400 !default;
$heightSm: 32px !default;
$heightMd: 40px !default;
$heightLg: 48px !default;
$spacingXXs: 4px !default;
$spacingXs: 8px !default;
$spacingSm: 12px !default;
$spacingMd: 16px !default;
$spacingLg: 24px !default;
$spacingXl: 32px !default;
$spacingXXL1: 48px !default;
$spacingXXL2: 64px !default;
$spacingXXL3: 96px !default;

$defaultBorderRadius: 4px;
