@use './palette';

$backgroundPrimary: var(--mm-color-warning-background-primary, palette.$W100);
$backgroundSecondary: var(--mm-color-warning-background-secondary, palette.$W200);
$backgroundTertiary: var(--mm-color-warning-background-tertiary, palette.$W500);
$borderPrimary: var(--mm-color-warning-border-primary, palette.$W500);
$textIconPrimary: var(--mm-color-warning-textIcon-primary, palette.$W500);

:export {
  backgroundPrimary: $backgroundPrimary;
  backgroundSecondary: $backgroundSecondary;
  backgroundTertiary: $backgroundTertiary;
  borderPrimary: $borderPrimary;
  textIconPrimary: $textIconPrimary;
}
