@use '/src/domains/core/tamagoshiv2/tokens/colors';
@use '/src/domains/core/tamagoshiv2/tokens/radius';
@use '/src/domains/core/tamagoshiv2/tokens/spacings';
@use '/src/domains/core/tamagoshiv2/tokens/typography';

.container {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: var(--banner-main-color);
  border-radius: radius.$card;

  &.isB2B {
    background-blend-mode: multiply;
    background-image: url('../../assets/b2b-background.png');
  }
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: radius.$card;
}

.labelContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 70px;
  height: 223px;
  width: 205px;
  border-bottom-left-radius: radius.$container;
  border-bottom-right-radius: radius.$container;
  padding: spacings.$xs;
  background-color: var(--banner-accent-color);
}

.label {
  display: flex;
  flex-direction: column;
  margin: auto spacings.$s;
  color: var(--banner-main-color);
}

.line {
  font-weight: typography.$fontWeightBold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.bigLine {
    font-size: 48px;

    &.secondLine {
      margin-top: -10px;
    }
  }

  &.middleLine {
    font-size: 24px;
  }

  &.smallLine {
    font-size: 20px;

    &.secondLine {
      margin-top: -5px;
    }
  }
}

.subtitle {
  margin-top: spacings.$s;
}

.brand {
  background-color: colors.$foundation-backgroundInverted;
  height: 55px;
  width: 100%;
  padding: spacings.$s;
  border-radius: radius.$container;
  margin-top: auto;
  display: flex;
  justify-content: center;
}

.brandImage {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
