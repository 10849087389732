@use '/src/domains/core/tamagoshiv2/tokens/colors';
@use '/src/domains/core/tamagoshiv2/tokens/radius';
@use '/src/domains/core/tamagoshiv2/tokens/spacings';
@use '/src/domains/core/tamagoshiv2/tokens/typography';
@use 'sass:math';
@import '@/core/tamagoshi/scss';

$breakpoint600: 600px;
$breakpoint1023: 1023px;
$breakpoint1424: 1424px;

.container {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: radius.$container;

  .block {
    width: 70%;
    height: 100%;
    padding: 20px spacings.$m;
    display: flex;
    border-radius: radius.$container;
    z-index: 1;
    background-color: var(--banner-main-color);

    @include size-below('md') {
      width: 100%;
    }

    @media only screen and (max-width: $breakpoint600) {
      padding: spacings.$m spacings.$s;
    }

    &.isB2B {
      background-blend-mode: multiply;
      background-image: url('../../assets/b2b-background.png');
    }
  }

  .image {
    position: absolute;
    top: 0;
    right: 0;

    width: 32.5%;
    height: 100%;
    object-fit: cover;
    background-color: var(--banner-nut-color);

    border-bottom-right-radius: radius.$container;
    border-top-right-radius: radius.$container;

    @include size-below('md') {
      display: none;
    }
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding-left: spacings.$m;
    padding-bottom: spacings.$xs;
    width: 60%;
  }

  .title {
    color: var(--banner-accent-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    --font-size-ratio: 0.016176;
    --font-size-offset: -1.035294px;
    font-size: calc(var(--font-size-offset) + 100vw * var(--font-size-ratio));
    font-weight: typography.$fontWeightBold;

    @media only screen and (min-width: $breakpoint1424) {
      font-size: 22px;
    }

    @media only screen and (min-width: $breakpoint600) and (max-width: $breakpoint1023) {
      --font-size-ratio: 0.026199;
      --font-size-offset: -0.80107px;
    }

    @media only screen and (max-width: $breakpoint600) {
      font-size: 15px;
    }
  }

  .subtitle {
    color: var(--banner-labor_text-color);

    --font-size-ratio: 0.0125;
    --font-size-offset: -0.8px;
    font-size: calc(var(--font-size-offset) + 100vw * var(--font-size-ratio));

    @media only screen and (min-width: $breakpoint1424) {
      font-size: 17px;
    }

    @media only screen and (min-width: $breakpoint600) and (max-width: $breakpoint1023) {
      --font-size-ratio: 0.019701;
      --font-size-offset: -0.153664px;
    }

    @media only screen and (max-width: $breakpoint600) {
      font-size: typography.$fontSizeSmall;
    }
  }

  .brand {
    background-color: colors.$foundation-backgroundInverted;
    height: 100%;
    width: 40%;
    padding: spacings.$l;
    border-radius: radius.$container;
    margin-top: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: $breakpoint600) {
      padding: spacings.$m;
    }

    @media only screen and (min-width: $mdWidth) {
      padding: spacings.$m;
    }
  }

  .brandImage {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
}
