@use './palette';

$backgroundDisabled: var(--mm-color-foundation-background-disabled, palette.$Sq300);
$backgroundInverted: var(--mm-color-foundation-background-inverted, palette.$Sq000);
$backgroundOverlayLight: var(--mm-color-foundation-background-overlay-light, rgba(palette.$Sq800, 32%));
$backgroundOverlayStrong: var(--mm-color-foundation-background-overlay-strong, rgba(palette.$Sq800, 64%));
$backgroundPrimary: var(--mm-color-foundation-background-primary, palette.$Sq200);
$backgroundQuaternary: var(--mm-color-foundation-background-quaternary, palette.$Sq700);
$backgroundQuinary: var(--mm-color-foundation-background-quinary, palette.$Sq800);
$backgroundSecondary: var(--mm-color-foundation-background-secondary, palette.$Sq400);
$backgroundTertiary: var(--mm-color-foundation-background-tertiary, palette.$Sq600);
$borderDisabled: var(--mm-color-foundation-border-disabled, palette.$Sq600);
$borderInverted: var(--mm-color-foundation-border-inverted, palette.$Sq000);
$borderPrimary: var(--mm-color-foundation-border-primary, palette.$Sq300);
$borderSecondary: var(--mm-color-foundation-border-secondary, palette.$Sq400);
$borderTertiary: var(--mm-color-foundation-border-secondary, palette.$Sq800);
$textIconDisabled: var(--mm-color-foundation-textIcon-disabled, palette.$Sq600);
$textIconInverted: var(--mm-color-foundation-textIcon-inverted, palette.$Sq000);
$textIconPrimary: var(--mm-color-foundation-textIcon-primary, palette.$Sq800);
$textIconSecondary: var(--mm-color-foundation-textIcon-secondary, palette.$Sq700);

:export {
  backgroundDisabled: $backgroundDisabled;
  backgroundInverted: $backgroundInverted;
  backgroundOverlayLight: $backgroundOverlayLight;
  backgroundOverlayStrong: $backgroundOverlayStrong;
  backgroundPrimary: $backgroundPrimary;
  backgroundQuaternary: $backgroundQuaternary;
  backgroundQuinary: $backgroundQuinary;
  backgroundSecondary: $backgroundSecondary;
  backgroundTertiary: $backgroundTertiary;
  borderDisabled: $borderDisabled;
  borderInverted: $borderInverted;
  borderPrimary: $borderPrimary;
  borderSecondary: $borderSecondary;
  borderTertiary: $borderTertiary;
  textIconDisabled: $textIconDisabled;
  textIconInverted: $textIconInverted;
  textIconPrimary: $textIconPrimary;
  textIconSecondary: $textIconSecondary;
}
