@import '../scss';

$height: 16px;

.root {
  height: $height;
  display: flex;
}

.icon {
  margin-right: $spacingXXs;
  color: $b500;
}
