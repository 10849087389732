@use 'sass:math';
@import '@/core/tamagoshi/scss';

$campaignBorderRadius: 8px;

.carouselSlide {
  position: relative;

  &:nth-child(2) {
    margin-left: 1px;
  }

  &:before {
    content: '';
    display: block;
    padding-bottom: math.div(206, 360) * 100%;

    @include size-above('md') {
      padding-bottom: math.div(300, 910) * 100%;
    }
  }
}

.carouselLink {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.carouselImg {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;

  @include size-above('md') {
    border-radius: $campaignBorderRadius;
    outline: 1px solid $n300;
    outline-offset: -1px;
  }
}
