@import '@/core/tamagoshi/scss';

.modalWrapper.modalWrapper {
  width: 400px;
  min-height: initial;

  @include size-below('md') {
    width: auto;
  }
}

.content {
  header {
    @include text('h4', 'bold');
    width: 344px;
  }

  p {
    @include text('body2');
    margin: 0;

    &:first-of-type {
      margin: $spacingMd 0;
    }
  }

  strong {
    font-weight: $fontWeightBold;
  }

  a {
    font-weight: $fontWeightSemiBold;
    text-decoration: underline;
    color: $blackColor;
  }
}
