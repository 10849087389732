@use '@/core/tamagoshiv2/tokens/spacings';
@import '@/core/tamagoshi/scss/mixins';

%carousel {
  grid-template-columns: 1fr auto;
}

%carouselCard {
  @include size-below('md') {
    margin-left: spacings.$s;

    &:first-child {
      margin-left: spacings.$m;
    }
  }

  @include size-above('md') {
    &:not(:first-child) {
      margin-left: spacings.$m;
    }
  }
}

%carouselControls {
  align-items: flex-start;

  svg {
    min-width: spacings.$l;
  }

  @include size-below('md') {
    margin-right: spacings.$m;
  }
}

%carouselSlider {
  margin-bottom: calc(-1 * #{spacings.$xl});
  margin-top: calc(-1 * #{spacings.$m});
  padding-bottom: spacings.$xl;
  padding-top: spacings.$m;

  @include size-below('md') {
    scroll-padding: spacings.$m;

    &:after {
      content: '';
      display: block;
      flex: 0 0 spacings.$m;
    }
  }
}

%carouselTitle {
  @include size-below('md') {
    margin-left: spacings.$m;
  }
}
