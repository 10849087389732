@use '@/core/tamagoshiv2/tokens/colors';

.root {
  $sizeRegular: 8px;
  $sizeSmall: 4px;
  background-color: colors.$foundation-textIconInverted;
  border-radius: 50%;
  border: 1px solid transparent;
  height: $sizeRegular;
  transition: background-color 0.3s ease-in;
  width: $sizeRegular;

  &.active {
    background-color: colors.$foundation-textIconPrimary;
  }

  &.small {
    height: $sizeSmall;
    width: $sizeSmall;
  }
}
